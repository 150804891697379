import {
  Carousel,
  CarouselContent,
  CarouselHandler,
  GridListItem,
} from '@iheartradio/web.accomplice/carousel';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { Text } from '@iheartradio/web.accomplice/text';
import { addToast } from '@iheartradio/web.accomplice/toast';
import { Playback } from '@iheartradio/web.playback';
import { useLocation, useNavigation } from '@remix-run/react';
import { createContext, useCallback, useEffect, useMemo } from 'react';
import { isNonNullish } from 'remeda';
import { $path } from 'remix-routes';

import { amp } from '~app/api/amp-client';
import { useUser } from '~app/contexts/user';
import { useScanPlay } from '~app/playback/controls/play/use-scan-play';
import { playback } from '~app/playback/playback';
import { isAnonymousUser } from '~app/utilities/user';

import { RadioDialFilters } from './filters';
import { ScanButton } from './scan-button';
import { useRadioDialData } from './state/radio-dial-data';
import { LiveRadioDialStationSlide } from './station-card/station-card';

const SMALL_GAP = '1.2rem';
const LARGE_GAP = '1.6rem';

const radioDialSlideGap = {
  xsmall: SMALL_GAP,
  small: SMALL_GAP,
  shmedium: SMALL_GAP,
  medium: SMALL_GAP,
  large: LARGE_GAP,
  xlarge: LARGE_GAP,
  xxlarge: LARGE_GAP,
} as const;

type ScanContextValue = {
  stopScan: () => void;
};

export const ScanContext = createContext<ScanContextValue>({
  stopScan: () => 0,
});

export function RadioDial() {
  const player = playback.usePlayer();
  const state = player.getState();

  const { profileId } = useUser();

  const location = useLocation();
  const navigation = useNavigation();

  const stopScan = useCallback(() => {
    player.getState().set('isScanning', false);
  }, [player]);

  const visibilityChangeHandler = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      stopScan();
    }
  }, [stopScan]);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () =>
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
  }, [visibilityChangeHandler]);

  const stopScanOnNavigation = useMemo(
    () =>
      navigation.state !== 'idle' &&
      navigation.location.pathname !== location.pathname,
    [location.pathname, navigation.location?.pathname, navigation.state],
  );

  useEffect(() => {
    if (stopScanOnNavigation) {
      stopScan();
    }
  }, [stopScanOnNavigation, stopScan]);

  const updateRecentlyPlayed = useCallback(async () => {
    const { status, queue, index } = state.deserialize();
    if (status === Playback.Status.Playing) {
      const currentLiveStation = queue[index];
      await amp.api.v2.playlists.postAddStation({
        params: {
          type: 'LIVE',
          profileId,
          contentId: currentLiveStation.id,
        },
        body: {
          addToFavorites: false,
        },
      });
    }
  }, [state, profileId]);

  useEffect(() => {
    return player.getState().subscribe({
      set(_, key, value) {
        if (key === 'isScanning' && typeof value === 'boolean' && !value) {
          updateRecentlyPlayed();
        }
      },
    });
  }, [player, updateRecentlyPlayed]);

  return (
    <ScanContext.Provider value={useMemo(() => ({ stopScan }), [stopScan])}>
      <_RadioDial />
    </ScanContext.Provider>
  );
}

export function _RadioDial() {
  const [liveRadioDialData, dispatch] = useRadioDialData();
  const userIsAnonymous = isAnonymousUser(useUser());

  const stationIds = useMemo(
    () =>
      liveRadioDialData.stations
        .map(station => station.id)
        .filter((stationId): stationId is number => isNonNullish(stationId)),
    [liveRadioDialData.stations],
  );

  const { doPlay: play } = useScanPlay({
    id: stationIds,
    location: liveRadioDialData.location,
    genre: liveRadioDialData.genre,
    context: 0,
  });

  const doPlay = useCallback(
    ({ toggleScan }: { toggleScan: boolean }) => {
      if (userIsAnonymous) {
        addToast({
          kind: 'info',
          text: 'Log in to scan your favorite live radio stations',
          actions: [
            {
              color: 'transparent',
              href: $path('/login/:action?', { action: 'auth' }),
              kind: 'tertiary',
              size: 'small',
              content: (
                <Text as="span" color="$gray600" kind="button-2">
                  Log in
                </Text>
              ),
            },
            {
              color: 'transparent',
              href: $path('/sign-up'),
              kind: 'tertiary',
              size: 'small',
              content: (
                <Text as="span" color="$gray600" kind="button-2">
                  Sign up
                </Text>
              ),
            },
          ],
        });
      } else {
        play({ toggleScan });
      }
    },
    [play, userIsAnonymous],
  );

  const noResults = useMemo(
    () =>
      liveRadioDialData.isFetching || liveRadioDialData.stations.length === 0,
    [liveRadioDialData.isFetching, liveRadioDialData.stations.length],
  );

  return (
    <Flex
      data-test="live-radio-dial"
      direction="column"
      gap={{ mobile: '$12', large: '$16' }}
    >
      <Carousel slideGap={radioDialSlideGap} slidesToScroll={1}>
        <CarouselHandler>
          <RadioDialFilters radioDialDispatch={dispatch} />
        </CarouselHandler>
        <CarouselContent
          css={{
            alignItems: noResults ? 'center' : 'flex-start',
            height: { mobile: '6.2rem', large: '8.2rem' },
            justifyContent: noResults ? 'center' : 'flex-start',
          }}
          data-test="live-radio-dial-stations"
          renderEmptyState={() => (
            <Text kind="body-3">
              No results currently available for this filter
            </Text>
          )}
        >
          {liveRadioDialData.isFetching ?
            <GridListItem textValue="Stations Loading">
              <Loading
                data-test="radio-dial-loading-stations"
                size={48}
                strokeWidth={5}
              />
            </GridListItem>
          : liveRadioDialData.stations.map((station, index) => (
              <LiveRadioDialStationSlide
                index={index}
                key={station.id}
                station={station}
              />
            ))
          }
        </CarouselContent>
      </Carousel>
      <Flex
        direction="row"
        justifyContent="center"
        px={{ mobile: '$16', large: '$32' }}
      >
        <ScanButton
          isDisabled={liveRadioDialData.stations.length < 3}
          onPress={() => {
            doPlay({ toggleScan: true });
          }}
        />
      </Flex>
    </Flex>
  );
}
