import { lightDark, sprinkles } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import {
  GridListItem,
  useCarousel,
} from '@iheartradio/web.accomplice/carousel';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { useHover } from '@iheartradio/web.accomplice/hooks';
import { Play as PlayIcon, Stop } from '@iheartradio/web.accomplice/icons';
import { Text } from '@iheartradio/web.accomplice/text';
import { Image, MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { useEffect, useMemo } from 'react';

import { playback } from '~app/playback/playback';
import { buildLiveUrl } from '~app/utilities/urls';

import type { LiveStation } from '../filters/state/queries';

export type LiveRadioDialStationCardProps = {
  station: LiveStation;
  index: number;
};

export function LiveRadioDialStationCard({
  station,
  index,
}: LiveRadioDialStationCardProps) {
  const player = playback.usePlayer();
  const { scrollToIndex } = useCarousel();

  const state = player.getState().deserialize();

  const playing = useMemo(
    () =>
      (state.status === Playback.Status.Playing ||
        state.status === Playback.Status.Buffering) &&
      state.queue[state.index].id === station.id,
    [state.index, state.queue, state.status, station.id],
  );

  useEffect(() => {
    if (playing) {
      scrollToIndex(index);
    }
  }, [index, playing, scrollToIndex]);

  return (
    <_LiveRadioDialStationCard
      description={station.description}
      id={station.id}
      name={station.name}
      playing={playing}
    />
  );
}

export function LiveRadioDialStationSlide({
  station,
  index,
}: LiveRadioDialStationCardProps) {
  const className = sprinkles({
    outlineColor: {
      focusVisible: 'brandBlack',
    },
  });
  const liveStationUrl = buildLiveUrl({ name: station.name, id: station.id });
  return (
    <GridListItem
      className={className}
      href={liveStationUrl}
      id={station.id}
      textValue={station.name}
    >
      <LiveRadioDialStationCard index={index} station={station} />
    </GridListItem>
  );
}

type _LiveRadioDialStationCardProps = {
  playing: boolean;
  onClick?: () => void;
} & Pick<LiveStation, 'name' | 'description' | 'id'>;

export function _LiveRadioDialStationCard({
  playing,
  name,
  description,
  id,
  onClick,
}: _LiveRadioDialStationCardProps) {
  const { hoverProps, isHovered } = useHover({});

  return (
    <Flex
      {...hoverProps}
      backgroundColor={
        isHovered ? lightDark('$gray200', '$gray600') : 'inherit'
      }
      borderRadius="$6"
      boxShadow="$elevation1"
      boxSizing="content-box"
      cursor="pointer"
      data-test="live-radio-dial-station-card"
      direction="row"
      gap={{ mobile: '$4', large: '$8' }}
      height={{ mobile: '5.2rem', large: '7.2rem' }}
      id={`station-${id}`}
      onClick={() => onClick?.()}
      padding={{ mobile: '$4', large: '$8' }}
      width={{ mobile: '14.8rem', large: '28.3rem' }}
    >
      <Box
        backgroundColor={lightDark('$brandWhite', '$brandBlack')}
        borderRadius="$6"
        boxShadow="$elevation1"
        display={{ mobile: 'none', large: 'block' }}
      >
        <Image
          alt={name ?? 'live station'}
          aspectRatio="1 / 1"
          placeholder={false}
          src={MediaServerURL.fromCatalog({
            type: 'live',
            id: id ?? 0,
          }).quality(50)}
          width={72}
        />
      </Box>
      <Flex direction="column" flexGrow={1} gap={{ mobile: '$4', large: '$8' }}>
        <Text
          as="p"
          color={playing ? '$brandRed' : 'inherit'}
          data-test="live-radio-dial-station-card-title"
          kind={{ mobile: 'subtitle-4', large: 'subtitle-2' }}
          lines={1}
        >
          {name}
        </Text>
        <Flex
          alignItems="flex-start"
          direction="row"
          flexGrow={1}
          gap="$4"
          justifyContent="space-between"
        >
          <Text
            as="p"
            data-test="live-radio-dial-station-card-description"
            kind={{ mobile: 'caption-4', large: 'body-4' }}
            lines={2}
          >
            {description}
          </Text>
          {/* Once the player components refactor is complete, this will need to be a "real" play button, but only for non-mobile */}
          <Button
            css={{
              alignSelf: 'flex-end',
              height: { mobile: '2.4rem', large: '4rem' },
              width: { mobile: '2.4rem', large: '4rem' },
            }}
            excludeFromTabOrder
            kind="primary"
            size="icon"
          >
            {playing ?
              <Stop size={{ mobile: 24, large: 40 }} />
            : <PlayIcon size={{ mobile: 24, large: 40 }} />}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
